export const UserIcon = [
  'M682.667 597.333h-341.333c-119.467 0-213.333 93.867-213.333 213.333v85.333c0 25.6 17.067 42.667 42.667 42.667s42.667-17.067 42.667-42.667v-85.333c0-72.533 55.467-128 128-128h341.333c72.533 0 128 55.467 128 128v85.333c0 25.6 17.067 42.667 42.667 42.667s42.667-17.067 42.667-42.667v-85.333c0-119.467-93.867-213.333-213.333-213.333z',
  'M512 512c119.467 0 213.333-93.867 213.333-213.333s-93.867-213.333-213.333-213.333-213.333 93.867-213.333 213.333 93.867 213.333 213.333 213.333zM512 170.667c72.533 0 128 55.467 128 128s-55.467 128-128 128-128-55.467-128-128 55.467-128 128-128z',
];

export const HamburgerIcon = [
  'M73.143 292.571h877.714c40.375 0 73.143-32.768 73.143-73.143s-32.768-73.143-73.143-73.143h-877.714c-40.375 0-73.143 32.768-73.143 73.143s32.768 73.143 73.143 73.143zM950.857 438.857h-877.714c-40.375 0-73.143 32.768-73.143 73.143s32.768 73.143 73.143 73.143h877.714c40.375 0 73.143-32.768 73.143-73.143s-32.768-73.143-73.143-73.143zM950.857 731.429h-877.714c-40.375 0-73.143 32.768-73.143 73.143s32.768 73.143 73.143 73.143h877.714c40.375 0 73.143-32.768 73.143-73.143s-32.768-73.143-73.143-73.143z',
];

export const TrackLocationIcon = [
  'M512 42.667c-260.267 0-469.333 209.067-469.333 469.333s209.067 469.333 469.333 469.333 469.333-209.067 469.333-469.333-209.067-469.333-469.333-469.333zM554.667 891.733v-123.733c0-25.6-17.067-42.667-42.667-42.667s-42.667 17.067-42.667 42.667v123.733c-179.2-21.333-320-162.133-337.067-337.067h123.733c25.6 0 42.667-17.067 42.667-42.667s-17.067-42.667-42.667-42.667h-123.733c17.067-179.2 157.867-320 337.067-337.067v123.733c0 25.6 17.067 42.667 42.667 42.667s42.667-17.067 42.667-42.667v-123.733c179.2 21.333 320 162.133 337.067 337.067h-123.733c-25.6 0-42.667 17.067-42.667 42.667s17.067 42.667 42.667 42.667h123.733c-17.067 179.2-157.867 320-337.067 337.067z',
];

export const LocationIcon = [
  'M512 0c-234.667 0-426.667 192-426.667 426.667 0 315.733 388.267 580.267 401.067 588.8 8.533 4.267 17.067 8.533 25.6 8.533s17.067-4.267 25.6-8.533c12.8-8.533 401.067-273.067 401.067-588.8 0-234.667-192-426.667-426.667-426.667zM512 930.133c-81.067-59.733-341.333-273.067-341.333-503.467 0-187.733 153.6-341.333 341.333-341.333s341.333 153.6 341.333 341.333c0 230.4-260.267 443.733-341.333 503.467z',
  'M512 256c-93.867 0-170.667 76.8-170.667 170.667s76.8 170.667 170.667 170.667c93.867 0 170.667-76.8 170.667-170.667s-76.8-170.667-170.667-170.667zM512 512c-46.933 0-85.333-38.4-85.333-85.333s38.4-85.333 85.333-85.333c46.933 0 85.333 38.4 85.333 85.333s-38.4 85.333-85.333 85.333z',
];

export const MapIcon = [
  'M1023.776 256.18v0c5.775-135.575-99.445-250.16-235.020-255.946-6.972-0.294-13.954-0.294-20.916 0v0c-135.575-5.775-250.16 99.445-255.946 235.020-0.294 6.972-0.294 13.954 0 20.916 0 127.973 255.946 426.57 255.946 426.57s255.946-298.597 255.946-426.57v0zM682.519 256.18v0c0-47.113 38.199-85.312 85.312-85.312s85.312 38.199 85.312 85.312c0 47.113-38.199 85.312-85.312 85.312s-85.312-38.199-85.312-85.312c0 0 0 0 0 0zM767.83 813.705l-64.837-75.505c-63.136-75.505-276.421-334.002-276.421-482.020v0c0.084-30.061 3.811-59.997 11.088-89.155l-139.061 46.494-298.597-127.973v810.478l298.597 127.973 383.908-127.973 341.258 127.973v-545.156c-57.046 91.16-120.928 177.869-191.098 259.358l-64.837 75.505z',
];

export const MapMarkerIcon = [
  'M512 0.005c-238.94 0-358.4 192.005-358.4 383.999s358.4 640.001 358.4 640.001c0 0 358.4-448.007 358.4-640.001s-119.47-383.999-358.4-383.999zM512 512.011c-65.983 0-119.47-57.309-119.47-127.996s53.487-127.996 119.47-127.996c65.983 0 119.47 57.309 119.47 127.996s-53.487 127.996-119.47 127.996z',
];

export const RatingIcon = [
  'M1022.477 395.002c-4.014-12.533-15.523-21.134-28.63-21.421l-340.975-6.922-111.897-320.373c-4.341-12.369-15.933-20.683-28.998-20.683s-24.656 8.274-28.998 20.683l-111.937 320.373-340.933 6.922c-13.107 0.287-24.616 8.888-28.63 21.421s0.328 26.295 10.813 34.241l271.511 204.83-98.627 324.387c-3.85 12.574 0.696 26.172 11.222 33.954s24.861 7.987 35.592 0.533l280.030-193.894 280.030 193.894c5.283 3.645 11.345 5.448 17.448 5.448 6.389 0 12.779-1.966 18.144-5.979 10.567-7.782 15.072-21.421 11.222-33.954l-98.707-324.387 271.552-204.83c10.485-7.946 14.827-21.667 10.813-34.241v0z'
];

export const SearchIcon = [
  'M925.867 866.133l-157.867-157.867c51.2-64 85.333-149.333 85.333-238.933 0-213.333-170.667-384-384-384s-384 170.667-384 384c0 213.333 170.667 384 384 384 89.6 0 174.933-29.867 238.933-85.333l157.867 157.867c8.533 8.533 21.333 12.8 29.867 12.8s21.333-4.267 29.867-12.8c17.067-17.067 17.067-42.667 0-59.733zM170.667 469.333c0-166.4 132.267-298.667 298.667-298.667s298.667 132.267 298.667 298.667c0 81.067-34.133 157.867-85.333 209.067 0 0 0 0 0 0s0 0 0 0c-55.467 55.467-128 85.333-209.067 85.333-170.667 4.267-302.933-128-302.933-294.4z',
];

export const PrefIcon = [
  'M42.677 213.342h127.993v42.661c0 47.123 38.209 85.332 85.332 85.332s85.332-38.209 85.332-85.332v-170.665c0-47.123-38.209-85.332-85.332-85.332s-85.332 38.209-85.332 85.332v42.661h-127.993c-23.562 0-42.661 19.099-42.661 42.661s19.099 42.661 42.661 42.661v0zM42.677 554.671h554.655v-85.332h-554.655c-23.562 0-42.661 19.099-42.661 42.661s19.099 42.661 42.661 42.661zM1023.994 170.67c0-23.562-19.099-42.661-42.661-42.661h-554.655v85.332h554.655c23.562 0 42.661-19.099 42.661-42.661zM0.006 853.33v0c0 23.562 19.099 42.661 42.661 42.661h213.326v-85.332h-213.326c-23.562 0-42.661 19.099-42.661 42.661 0 0 0 0 0 0v0zM981.333 469.339h-127.993v-42.661c0-47.123-38.209-85.332-85.332-85.332s-85.332 38.209-85.332 85.332v170.665c0 47.123 38.209 85.332 85.332 85.332s85.332-38.209 85.332-85.332v-42.661h127.993c23.562 0 42.661-19.099 42.661-42.661s-19.099-42.661-42.661-42.661v0zM981.333 810.669h-469.323v-42.661c0-47.123-38.209-85.332-85.332-85.332s-85.332 38.209-85.332 85.332v170.665c0 47.123 38.209 85.332 85.332 85.332s85.332-38.209 85.332-85.332v-42.661h469.323c23.562 0 42.661-19.099 42.661-42.661s-19.099-42.661-42.661-42.661v0z',
];

export const TimeIcon = [
  'M512 0v0c-282.764 0-512 229.225-512 512s229.225 512 512 512c282.764 0 512-229.225 512-512v0c0-282.764-229.225-512-512-512v0zM554.672 597.333h-256c-23.562 0-42.661-19.099-42.661-42.661s19.099-42.661 42.661-42.661h170.667v-256c0-23.562 19.099-42.661 42.661-42.661s42.661 19.099 42.661 42.661c0 0 0 0 0 0v341.333z',
];

export const EditIcon = [
  'M107.936 642.142l-107.94 381.862 383.997-107.94 426.662-426.662-274.774-276.045-427.946 428.797zM967.234 56.766c-75.787-75.682-198.555-75.682-274.342 0l-95.573 96.425 273.49 273.49 96.425-96.425c75.682-75.787 75.682-198.555 0-274.342v0.852z',
];

export const CalendarIcon = [
  'M7.114 932.593v0c0 46.439 37.668 84.168 84.168 84.168h841.248c46.439 0 84.168-37.668 84.168-84.168v-462.739h-1009.523v462.739zM932.592 91.283h-126.19v-42.022c0-23.22-18.803-42.022-42.022-42.022s-42.022 18.803-42.022 42.022v42.022h-420.593v-42.022c0-23.22-18.803-42.022-42.022-42.022s-42.022 18.803-42.022 42.022v42.022h-126.19c-46.439 0-84.168 37.668-84.168 84.168 0 0 0 0 0 0v210.297h1009.523v-210.297c0-46.439-37.668-84.168-84.168-84.168zM259.556 301.641c-23.22 0-42.022-18.803-42.022-42.022s18.803-42.022 42.022-42.022c23.22 0 42.022 18.803 42.022 42.022s-18.803 42.022-42.022 42.022zM764.317 301.641c-23.22 0-42.022-18.803-42.022-42.022s18.803-42.022 42.022-42.022c23.22 0 42.022 18.803 42.022 42.022s-18.803 42.022-42.022 42.022z',
];

export const FlagIcon = [
  'M107.936 642.142l-107.94 381.862 383.997-107.94 426.662-426.662-274.774-276.045-427.946 428.797zM967.234 56.766c-75.787-75.682-198.555-75.682-274.342 0l-95.573 96.425 273.49 273.49 96.425-96.425c75.682-75.787 75.682-198.555 0-274.342v0.852z',
];

export const LikeIcon = [
  'M107.936 642.142l-107.94 381.862 383.997-107.94 426.662-426.662-274.774-276.045-427.946 428.797zM967.234 56.766c-75.787-75.682-198.555-75.682-274.342 0l-95.573 96.425 273.49 273.49 96.425-96.425c75.682-75.787 75.682-198.555 0-274.342v0.852z',
];

export const FullStarIcon = [
  'M950.857 369.714c0 10.286-7.429 20-14.857 27.429l-207.429 202.286 49.143 285.714c0.571 4 0.571 7.429 0.571 11.429 0 14.857-6.857 28.571-23.429 28.571-8 0-16-2.857-22.857-6.857l-256.571-134.857-256.571 134.857c-7.429 4-14.857 6.857-22.857 6.857-16.571 0-24-13.714-24-28.571 0-4 0.571-7.429 1.143-11.429l49.143-285.714-208-202.286c-6.857-7.429-14.286-17.143-14.286-27.429 0-17.143 17.714-24 32-26.286l286.857-41.714 128.571-260c5.143-10.857 14.857-23.429 28-23.429s22.857 12.571 28 23.429l128.571 260 286.857 41.714c13.714 2.286 32 9.143 32 26.286z',
];

export const EmptyStarIcon = [
  'M649.714 573.714l174.857-169.714-241.143-35.429-108-218.286-108 218.286-241.143 35.429 174.857 169.714-41.714 240.571 216-113.714 215.429 113.714zM950.857 369.714c0 10.286-7.429 20-14.857 27.429l-207.429 202.286 49.143 285.714c0.571 4 0.571 7.429 0.571 11.429 0 15.429-6.857 28.571-23.429 28.571-8 0-16-2.857-22.857-6.857l-256.571-134.857-256.571 134.857c-7.429 4-14.857 6.857-22.857 6.857-16.571 0-24-13.714-24-28.571 0-4 0.571-7.429 1.143-11.429l49.143-285.714-208-202.286c-6.857-7.429-14.286-17.143-14.286-27.429 0-17.143 17.714-24 32-26.286l286.857-41.714 128.571-260c5.143-10.857 14.857-23.429 28-23.429s22.857 12.571 28 23.429l128.571 260 286.857 41.714c13.714 2.286 32 9.143 32 26.286z',
];

export const HalfStarIcon = [
  'M677.714 546.857l146.857-142.857-241.143-35.429-17.143-34.286-90.857-184v550.286l33.714 17.714 181.714 96-34.286-202.857-6.857-37.714zM936 397.143l-207.429 202.286 49.143 285.714c4 25.143-5.143 40-22.857 40-6.286 0-14.286-2.286-22.857-6.857l-256.571-134.857-256.571 134.857c-8.571 4.571-16.571 6.857-22.857 6.857-17.714 0-26.857-14.857-22.857-40l49.143-285.714-208-202.286c-24.571-24.571-16.571-48.571 17.714-53.714l286.857-41.714 128.571-260c7.429-15.429 17.714-23.429 28-23.429v0c10.286 0 20 8 28 23.429l128.571 260 286.857 41.714c34.286 5.143 42.286 29.143 17.143 53.714z',
];

export const UpArrowIcon = [
  'M576.002 959.199v-742.4l339.199 339.199c25.6 25.6 64 25.6 89.599 0s25.6-64 0-89.599l-448.001-448.001c-6.4-6.4-12.799-12.799-19.2-12.799-12.799-6.4-31.999-6.4-51.199 0-6.4 6.4-12.799 6.4-19.2 12.799l-448.001 448.001c-12.799 12.799-19.2 31.999-19.2 44.8s6.4 31.999 19.2 44.8c25.6 25.6 64 25.6 89.599 0l339.199-339.199v742.4c0 38.4 25.6 64 64 64s64-25.6 64-64z',
];

export const DownArrowIcon = [
  'M447.998 64.801v742.4l-339.199-339.199c-25.6-25.6-64-25.6-89.599 0s-25.6 64 0 89.599l448.001 448.001c6.4 6.4 12.799 12.799 19.2 12.799 12.799 6.4 31.999 6.4 51.199 0 6.4-6.4 12.799-6.4 19.2-12.799l448.001-448.001c12.799-12.799 19.2-31.999 19.2-44.8s-6.4-31.999-19.2-44.8c-25.6-25.6-64-25.6-89.599 0l-339.199 339.199v-742.4c0-38.4-25.6-64-64-64s-64 25.6-64 64z',
];

export const LeftArrowIcon = [
  'M959.199 447.998h-742.4l339.199-339.199c25.6-25.6 25.6-64 0-89.599s-64-25.6-89.599 0l-448.001 448.001c-6.4 6.4-12.799 12.799-12.799 19.2-6.4 12.799-6.4 31.999 0 51.199 6.4 6.4 6.4 12.799 12.799 19.2l448.001 448.001c12.799 12.799 31.999 19.2 44.8 19.2s31.999-6.4 44.8-19.2c25.6-25.6 25.6-64 0-89.599l-339.199-339.199h742.4c38.4 0 64-25.6 64-64s-25.6-64-64-64z',
];

export const RightArrowIcon = [
  'M64.801 576.002h742.4l-339.199 339.199c-25.6 25.6-25.6 64 0 89.599s64 25.6 89.599 0l448.001-448.001c6.4-6.4 12.799-12.799 12.799-19.2 6.4-12.799 6.4-31.999 0-51.199-6.4-6.4-6.4-12.799-12.799-19.2l-448.001-448.001c-12.799-12.799-31.999-19.2-44.8-19.2s-31.999 6.4-44.8 19.2c-25.6 25.6-25.6 64 0 89.599l339.199 339.199h-742.4c-38.4 0-64 25.6-64 64s25.6 64 64 64z',
];

export const SmallUpArrowIcon = [
  'M960.026 832c-17.816 0-35.54-7.396-48.192-21.856l-399.834-456.954-399.834 456.954c-23.272 26.6-63.712 29.3-90.308 6.020-26.6-23.276-29.296-63.708-6.020-90.308l448-512c12.152-13.888 29.708-21.856 48.164-21.856s36.012 7.968 48.164 21.856l448 512c23.276 26.6 20.584 67.032-6.020 90.308-12.14 10.624-27.164 15.836-42.116 15.836z',
];

export const SmallRightArrowIcon = [
  'M192 960.026c0-17.816 7.396-35.54 21.856-48.192l456.954-399.834-456.954-399.834c-26.6-23.272-29.3-63.712-6.020-90.308 23.276-26.6 63.708-29.296 90.308-6.020l512 448c13.888 12.152 21.856 29.708 21.856 48.164s-7.968 36.012-21.856 48.164l-512 448c-26.6 23.276-67.032 20.584-90.308-6.020-10.624-12.14-15.836-27.164-15.836-42.116z',
];
export const SmallDownArrowIcon = [
  'M63.974 192c17.816 0 35.54 7.396 48.192 21.856l399.834 456.954 399.834-456.954c23.272-26.6 63.712-29.3 90.308-6.020 26.6 23.276 29.296 63.708 6.020 90.308l-448 512c-12.152 13.888-29.708 21.856-48.164 21.856s-36.012-7.968-48.164-21.856l-448-512c-23.276-26.6-20.584-67.032 6.020-90.308 12.14-10.624 27.164-15.836 42.116-15.836z',
];

export const SmallLeftArrowIcon = [
  'M832 63.974c0 17.816-7.396 35.54-21.856 48.192l-456.954 399.834 456.954 399.834c26.6 23.272 29.3 63.712 6.020 90.308-23.276 26.6-63.708 29.296-90.308 6.020l-512-448c-13.888-12.152-21.856-29.708-21.856-48.164s7.968-36.012 21.856-48.164l512-448c26.6-23.276 67.032-20.584 90.308 6.020 10.624 12.14 15.836 27.164 15.836 42.116z',
];

export const CloseIcon = [
  'M700.107 505.013l293.118-293.759c45.957-53.695 39.741-134.469-13.954-180.479-47.932-41.027-118.592-41.027-166.525 0l-293.759 293.118-293.759-293.118c-53.695-45.957-134.469-39.741-180.479 13.954-41.027 47.932-41.027 118.592 0 166.525l293.118 293.759-293.118 293.759c-53.695 45.957-59.962 126.784-13.954 180.479s126.784 59.962 180.479 13.954c4.994-4.289 9.666-8.958 13.954-13.954l293.759-293.118 293.759 293.118c53.695 45.957 134.469 39.741 180.479-13.954 41.027-47.932 41.027-118.592 0-166.525l-293.118-293.759z',
];

export const CheckCircleIcon = [
  'M873.397 149.333c-200.297-199.604-524.471-199.037-724.064 1.26s-199.037 524.471 1.26 724.075c199.803 199.11 523.012 199.11 722.815 0v0c200.297-199.604 200.853-523.778 1.26-724.075-0.42-0.42-0.84-0.84-1.26-1.26v0zM756.921 368.634l-282.028 328.963-202.67-151.892c-18.847-14.143-22.669-40.887-8.536-59.734s40.887-22.669 59.734-8.536l138.662 104.107 229.969-268.368c15.319-17.913 42.252-20.013 60.165-4.693s20.013 42.252 4.693 60.165z',
];

export const CloseCircleIcon = [
  'M512 1024c-282.764 0-512-229.236-512-512s229.236-512 512-512c282.764 0 512 229.236 512 512s-229.236 512-512 512zM512 938.667c235.641 0 426.667-191.026 426.667-426.667s-191.026-426.667-426.667-426.667c-235.641 0-426.667 191.026-426.667 426.667s191.026 426.667 426.667 426.667zM713.043 652.385c16.726 16.59 16.842 43.606 0.252 60.343-0.084 0.084-0.168 0.168-0.252 0.252-16.59 16.726-43.606 16.842-60.343 0.252-0.084-0.084-0.168-0.168-0.252-0.252l-140.374-140.805-140.374 140.805c-16.59 16.726-43.606 16.842-60.343 0.252-0.084-0.084-0.168-0.168-0.252-0.252-16.726-16.59-16.842-43.606-0.252-60.343 0.084-0.084 0.168-0.168 0.252-0.252l140.805-140.374-140.805-140.374c-16.726-16.726-16.726-43.858 0-60.585s43.858-16.726 60.585 0l140.374 140.805 140.374-140.805c16.726-16.726 43.858-16.726 60.585 0s16.726 43.858 0 60.585l-140.805 140.374 140.805 140.374z',
];

export const CloseCircleFilledIcon = [
  'M512 0v0c-282.764 0-512 229.225-512 512s229.225 512 512 512c282.764 0 512-229.225 512-512v0c0-282.764-229.225-512-512-512v0zM712.959 652.374c16.726 16.59 16.842 43.606 0.252 60.343-0.084 0.084-0.168 0.168-0.252 0.252-16.59 16.726-43.606 16.842-60.343 0.252-0.084-0.084-0.168-0.168-0.252-0.252l-140.374-140.805-140.374 140.805c-16.59 16.726-43.606 16.842-60.343 0.252-0.084-0.084-0.168-0.168-0.252-0.252-16.726-16.59-16.842-43.606-0.252-60.343 0.084-0.084 0.168-0.168 0.252-0.252l140.805-140.374-140.805-140.374c-16.726-16.726-16.726-43.858 0-60.585s43.858-16.726 60.585 0l140.374 140.805 140.374-140.805c16.726-16.726 43.858-16.726 60.585 0s16.726 43.858 0 60.585l-140.805 140.374 140.805 140.374z',
];

export const CancelIcon = [
  'M571.733 512l226.133-226.133c17.067-17.067 17.067-42.667 0-59.733s-42.667-17.067-59.733 0l-226.133 226.133-226.133-226.133c-17.067-17.067-42.667-17.067-59.733 0s-17.067 42.667 0 59.733l226.133 226.133-226.133 226.133c-17.067 17.067-17.067 42.667 0 59.733 8.533 8.533 17.067 12.8 29.867 12.8s21.333-4.267 29.867-12.8l226.133-226.133 226.133 226.133c8.533 8.533 21.333 12.8 29.867 12.8s21.333-4.267 29.867-12.8c17.067-17.067 17.067-42.667 0-59.733l-226.133-226.133z',
];

export const DownloadIcon = [
  'M48.762 452.892h294.788v-452.892h336.9v452.892h294.788l-463.238 571.108z',
];

export const PrintIcon = [
  'M870.401 298.666h-716.801c-84.992 0-153.6 71.467-153.6 160v320h204.8v213.334h614.4v-213.334h204.8v-320c0-88.534-68.608-160-153.6-160v0zM716.801 885.333h-409.601v-266.666h409.601v266.666zM870.401 512c-28.16 0-51.2-24-51.2-53.333s23.040-53.333 51.2-53.333c28.16 0 51.2 24 51.2 53.333s-23.040 53.333-51.2 53.333v0zM819.2 32h-614.4v213.334h614.4v-213.334z',
];

export const ViewIcon = [
  'M512 170.667c-232.727 0-431.477 141.539-512 341.333 80.523 199.794 279.273 341.333 512 341.333s431.477-141.539 512-341.333c-80.523-199.794-279.273-341.333-512-341.333v0zM512 739.555c-128.465 0-232.727-101.945-232.727-227.555s104.262-227.555 232.727-227.555c128.465 0 232.727 101.945 232.727 227.555s-104.262 227.555-232.727 227.555v0zM512 375.467c-77.265 0-139.637 60.985-139.637 136.533s62.371 136.533 139.637 136.533c77.265 0 139.637-60.985 139.637-136.533s-62.371-136.533-139.637-136.533v0z',
];

export const SendIcon = [
  'M921.6 102.4h-819.2c-56.32 0-101.888 46.080-101.888 102.4l-0.512 614.4c0 56.32 46.080 102.4 102.4 102.4h819.2c56.32 0 102.4-46.080 102.4-102.4v-614.4c0-56.32-46.080-102.4-102.4-102.4v0zM921.6 307.2l-409.6 256-409.6-256v-102.4l409.6 256 409.6-256v102.4z',
];

export const landingNetworkIcon = [
  'M869.684 941.264c-42.336 0-76.688-34.306-76.688-76.688 0-42.336 34.352-76.688 76.688-76.688s76.688 34.352 76.688 76.688c0 42.375-34.352 76.688-76.688 76.688z',
  'M664.514 633.056l154.223 174.326',
  'M274.444 380.174l-136.628-7.388',
  'M241.115 969.043c-32.846 0-59.505-26.66-59.505-59.505s26.66-59.465 59.505-59.465c32.885 0 59.505 26.62 59.505 59.465s-26.62 59.505-59.505 59.505z',
  'M343.667 160.023c-29.026 0-52.554-23.528-52.554-52.513 0-29.026 23.528-52.554 52.554-52.554s52.592 23.528 52.592 52.554c0 28.986-23.567 52.513-52.592 52.513z',
  'M86.058 417.659c-29.026 0-52.554-23.528-52.554-52.554s23.528-52.513 52.554-52.513c29.026 0 52.554 23.487 52.554 52.513s-23.528 52.554-52.554 52.554z',
  'M937.942 221.121c-29.026 0-52.554-23.528-52.554-52.554 0-28.986 23.528-52.513 52.554-52.513s52.554 23.528 52.554 52.513c0 29.026-23.528 52.554-52.554 52.554z',
  'M814.463 237.746l-38.080 23.316',
  'M314.319 786.895l65.136-107.035',
  'M368.787 153.378l27.479 49.935',
  'M702.597 420.792c0 98.235-79.649 177.932-177.974 177.932-98.235 0-177.881-79.688-177.881-177.932 0-98.285 79.649-177.932 177.881-177.932 98.324 0 177.974 79.649 177.974 177.932z',
];

export const landingFreeIcon = [
  'M32.026 82.609l11.232 322.464c0.459 12.403 5.532 24.139 14.29 32.897l539.7 539.7c19.11 19.11 50.076 19.11 69.187 0l311.233-311.233c19.11-19.11 19.11-50.076 0-69.187l-539.659-539.7c-8.758-8.758-20.535-13.872-32.897-14.29l-322.505-11.232c-28.329-0.964-51.544 22.251-50.58 50.58z',
  'M208.305 683.913l92.528 268.361c8.844 25.563 36.667 39.096 62.189 30.298l104.219-35.911',
  'M293.282 185.525c29.752 29.752 29.752 77.986 0 107.738s-77.986 29.752-107.738 0c-29.752-29.752-29.752-77.986 0-107.738 29.752-29.712 77.986-29.712 107.738 0z',
];

export const landingProfileIcon = [
  'M816.248 911.893c0 38.831-31.566 70.354-70.354 70.354h-467.888c-38.789 0-70.333-31.524-70.438-72.442l-40.25-418.995c0-38.831 31.544-70.354 70.333-70.354h548.597c38.809 0 70.354 31.524 70.458 68.267l-40.459 423.171zM348.673 205.218c0-90.188 73.256-163.464 163.276-163.464s163.256 73.277 163.256 163.464v173.485h-326.532v-173.485zM786.247 378.702h-69.289v-173.485c0-113.152-91.962-205.217-205.009-205.217-113.068 0-205.030 92.067-205.030 205.217v173.485h-69.269c-61.816 0-112.086 50.313-112.003 113.987l40.271 419.203c0 61.796 50.292 112.108 112.086 112.108h467.888c61.816 0 112.108-50.313 112.024-110.228l40.438-422.962c0-61.796-50.292-112.108-112.108-112.108v0z',
  'M541.134 834.441h-58.351l-15.637-131.941c0.584-24.217 20.439-43.841 44.76-43.841 24.342 0 44.217 19.624 44.823 43.841l-15.596 131.941zM511.907 616.906c-47.724 0-86.513 38.831-86.513 86.638 0 0.835 0.042 1.67 0.125 2.506l17.975 151.773c1.253 10.648 10.146 18.372 20.731 18.372h95.491c10.564 0 19.479-7.725 20.731-18.372l17.913-151.773c0.084-0.835 0.146-1.67 0.146-2.506 0-47.808-38.851-86.638-86.596-86.638v0z',
];

export const landingFavIcon = [
  'M911.323 480.995l-386.811 445.709c-4.314 5.016-10.596 7.827-17.199 7.827v20.068l-0.121-20.068c-6.663 0-12.964-3.010-17.26-8.027l-385.547-455.944c-30.845-41.34-47.14-90.708-47.14-142.482 0-131.647 107.103-238.608 238.728-238.608 78.064 0 152.837 36.926 199.977 98.934 3.792 5.016 9.692 8.027 15.974 8.027 6.262 0 12.162-3.010 15.953-8.027 47.179-62.010 121.973-98.934 200.077-98.934 131.666 0 238.788 106.963 238.788 238.608 0 55.99-19.767 110.374-55.429 152.918v0zM727.961 49.333c-82.199 0-161.225 35.318-216.032 95.725-54.786-60.405-133.794-95.725-215.951-95.725-153.76 0-278.864 125.023-278.864 278.744 0 60.605 19.064 118.2 55.326 166.763l386.851 457.551c11.882 14.047 29.258 22.276 47.761 22.276h0.26c18.242 0 35.56-7.827 47.501-21.673l387.051-445.911c41.922-50.171 65.021-113.584 65.021-179.007 0-153.721-125.123-278.744-278.925-278.744v0z',
];

export const HeartIcon = [
  'M377.594 18.938c-53.815 0-100.129 43.777-121.582 89.5-21.469-45.722-67.789-89.5-121.608-89.5-74.191 0-134.404 60.22-134.404 134.416 0 150.923 152.25 190.497 256.011 339.709 98.077-148.288 255.989-193.603 255.989-339.709 0-74.196-60.215-134.416-134.406-134.416z',
];

