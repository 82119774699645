import React from 'react';

const SVGIcon = ({icon}) => {
    return (
        <svg style={{"fontSize": "inherit", "color": "inherit", "display": "flex", "justifyContent": "center", "alignItems": "center",}} fill="currentColor" width="1em" height="1em" viewBox="0 0 1024 1024">
            <path style={{"color": "inherit"}} d={icon.join(' ')} />
        </svg>
    );
};

export default SVGIcon;
